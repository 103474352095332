div.section-icon-bursts {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    div.row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0;
        div.icon-bursts {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0;
            div.row {
                @media screen and (max-width: $size-max-m-2) {
                    flex-direction: column;
                }
                .col-xs-12, .col-sm-4 {
                    padding: 0!important;
                    margin: 0;
                }

                div.content-icons {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;

                    img {
                        @media screen and (max-width: $size-max-m-2) {
                            height: 45px;
                        }
                    }
                }

                div.icon-burst {
                    padding: 0!important;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    background: $color-white;
                    align-items: center;
                    overflow: hidden;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 100%;
                    }

                    &:nth-child(1) {
                        a {
                            div.text-bloc {
                                width: 95%;
                                height: 90%;
                            }
                        }
                    }
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        @media screen and (max-width: $size-max-m-2) {
                            width: 100%;
                        }
                        div.element-burst {
                            width: 100%;
                            @media screen and (max-width: $size-max-m-2) {
                                width: 100%;
                            }
                        }
                        div.content-img {
                            background: $color-white;
                            width: 100%;
                            @media screen and (max-width: $size-max-m-2) {
                                width: 100%;
                            }
                            img {
                                background: $color-white;
                                transition: .5s;
                                width: 100%;
                                @media screen and (max-width: $size-max-m-2) {
                                    width: 100%;
                                }
                            }
                        }                        

                        &:hover {
                            div.element-burst {
                                div.content-img {
                                    img {
                                        transform: scale(1.5);
                                    }
                                }
                            }
                        }

                        div.element-burst {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            //padding: 15px;

                            div.text-bloc {
                                width: 95%;
                                height: 90%;
                                position: absolute;
                                border: 1px solid $color-white;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;                                

                                h3 {
                                    // text-shadow: 2px 0 0px rgba(14, 14, 14, 0.5), 
                                    //     3px 2px 0px rgba(14, 14, 14, 0.5), 
                                    //     3px 0 3px rgba(14, 14, 14, 0.5), 
                                    //     5px 0 3px rgba(14, 14, 14, 0.5), 
                                    //     6px 2px 3px rgba(12, 12, 12, 0.5),
                                    //     17px 0 45px rgba(7, 7, 7, 0.5), 
                                    //     17px 2px 45px rgba(14, 14, 14, 0.5);                                        
                                    color: $color-white;
                                    text-align: center;
                                    font-size: 35px;
                                    font-weight: 400;
                                    transition: .5s;
                                    text-transform: none;
                                    @media screen and (max-width: $size-max-2) {
                                        font-size: 30px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-3) {
                                            font-size: 25px;
                                            transition: .5s;
                                            @media screen and (max-width: $size-max-4) {
                                                font-size: 22px;
                                                transition: .5s;
                                                @media screen and (max-width: $size-max-m-1) {
                                                    font-size: 32px;
                                                    transition: .5s;
                                                    @media screen and (max-width: $size-max-m-2) {
                                                        font-size: 25px;
                                                        transition: .5s;
                                                        @media screen and (max-width: $size-max-m-4) {
                                                            font-size: 22px;
                                                            font-weight: 400;
                                                            transition: .5s;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }                                    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}