
//********************/
// COLORS
//
$color-1: rgb(0, 147, 216); // needed
$color-2: rgb(73, 75, 62); // needed
$color-3: #2351b1;
$color-4: rgba(255, 255, 255, 0.98);
$color-5: #093188;

$color-primary: $color-1; // needed
$color-secondary: $color-2; // needed
$color-tertiary: $color-1; // needed

$color-black: #000; // needed
$color-white: #fff; // needed
$color-grey: #58585a; // needed
$color-grey-dark: #353535; // needed body
$color-grey-darker: #262523; // footer
$color-grey-light: #b5b5b5;

$color-error: red; // needed error messages
$color-success: green; // needed success messages

$input_height: 50px;
$input_back_color: #fff;
$input_text_color: #000;
$input_text_size: 20px;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: 18px;

$input_border_color: $color-primary;
$input_border_radius: 0;
$input_border_size: 1px;
$input_border_style: solid;

$input_margin: 10px 0;
$input_text_color: $color-white;
$input_label_color: $color-white;
$label_checkbox_border_color: $label-color;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: white;
$message_errors_border_color: hsla(0, 100%, 55%, 1);
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: white;
$message_success_border_color: hsla(120, 100%, 50%, 1);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: hsla(120, 100%, 50%, 0.5);

div.section-form.fsb-custom-form {
    padding: 85px 0;
    background: url(../images/bg_formulaire_orange.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: 100% 100%;
    transition: 0.5s;

    div.header-form-fsb {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: center;
        transition: .5s;
        padding-bottom: 45px;
        h3 {
            font-weight: 500;
            font-size: 1.8vw;
            text-transform: none;
            color: $color-white;
            transition: .5s;
            @media screen and (max-width: 1575px) {
                font-size: 25px;
                transition: .5s;
                @media screen and (max-width: 767px) {
                    text-align: center;
                    font-size: 22px;
                    transition: .5s;
                }
            }
        }
    }
    div.module-contact-form {
        div.fsb-block-footer-form {
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: center;
            div.form-group {
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;
            }
        }
    }

    section.input-section,
    div.input-section {
        input[type=text],
        input[type=email],
        input[type=tel] {
            height: 45px;
            font-size: 16px;
            font-weight: 400;
            border-radius: 1px;
            color: $color-black;
            transition: .5s;
            @media screen and (max-width: 1575px) {
                font-size: 14px;
                transition: .5s;
                @media screen and (max-width: 767px) {
                    font-size: 14px;
                    transition: .5s;
                }
            }
        }
        label {
            font-size: 18px;
            font-weight: 500;
            color: $color-white;
            transition: .5s;
            @media screen and (max-width: 1575px) {
                font-size: 15px;
                transition: .5s;
                @media screen and (max-width: 767px) {
                    font-size: 15px;
                    transition: .5s;
                }
            }
        }
        textarea {
            font-size: 16px;
            font-weight: 400;
            border-radius: 1px;
            color: $color-black;
            resize: vertical;
            transition: .5s;
        }
        label.btn-fileDoc {
            width: 50%;
            height: 50px;
            min-width: 300px;
            font-weight: 400;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-white;
            font-family: $font-family-2;
            background: $color-black;
            color: $color-white;
            font-size: 18px;
            padding: 0 25px;
            transition: .5s;
            &:hover {
                background: $color-grey;
                transition: .5s;
            }
        }
        div.btn-submit {
            button {
                width: 50%;
                height: 50px;
                min-width: 300px;
                font-weight: 400;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $color-white;
                font-family: $font-family-2;
                background: $color-black;
                color: $color-white;
                font-size: 18px;
                padding: 0 25px;
                transition: .5s;
                &:hover {
                    background: $color-1;
                    transition: .5s;
                }
            }
        }
    }

    div#fp {
        .preview-file {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            transition: .5s;
        }
    }    

    div.help-block.with-errors {
        ul.list-unstyled {
            li {
                color: #92100e;
                position: absolute;
                line-height: 15px;
                margin-top: -4px;
                font-size: 13px;
                transition: .5s;
            }
        }
    }
    

    div.list-checkbox {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        transition: .5s;
        div.content-checkboxs {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
            transition: .5s;
            flex-wrap: wrap;
        }
        /* The container */
        .container {
            width: 30%;
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            @media screen and (max-width: 1575px) {
                font-size: 15px;
                transition: .5s;
                @media screen and (max-width: 767px) {
                    font-size: 15px;
                    transition: .5s;
                    @media screen and (max-width: 480px) {
                        width: 45%;
                        transition: .5s;
                    }
                }
            }
        }

        /* Hide the browser's default checkbox */
        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: $color-grey-light;
            transition: .5s;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
            background-color: $color-3;
            transition: .5s;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
            background-color: $color-3;
            transition: .5s;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            transition: .5s;
        }
    }    
}

div.loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display:none;
    background-color: rgba(0,0,0,0.7);
    background-size: cover;
    z-index: 9999;
    &.active {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid $bg-color-body;
    border-radius: 50%;
    border-top: 16px solid $color-3;
    border-bottom: 16px solid $color-2;
    width: 120px;
    height: 120px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.8), inset 0px 0px 5px rgba(0,0,0,0.8);
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}