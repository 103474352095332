div.wyswyg2 {
    margin-top: 35px;
    transition: .5s;
    h1,
    h2,
    h3,
    h5,
    h6 {
        color: $color-3;
        font-weight: 500;
        transition: .5s;
        span, label {
            color: $color-3;
            font-weight: 500;
            transition: .5s;
            background: transparent;
        }
    }    
    p {
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
        margin-top: 0;
        @media screen and (max-width: $size-max-m-3) {
            font-size: 15px;
            transition: .5s;
        }
    }
    ul {
        @media screen and (max-width: 550px) {
            list-style-type: disc!important;
            transition: .5s;
        }
        li {
            font-weight: 300;
            text-transform: none;
            color: $color-white;
            line-height: 25px;
            font-size: 18px;
            @media screen and (max-width: 550px) {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                transition: .5s;

                line-height: 25px;
                font-size: 16px;
            }
        }
    }
    div.see-more {
        width: 100%;
        @media screen and (max-width: 550px) {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .5s;
        }
    }
}