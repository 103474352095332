// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

section.section-01-index {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    width: 100%;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: url(../images/bg_zone01_paralax.jpg) no-repeat center center;
        background-attachment: fixed;
        background-size: cover;
        transition: .5s;
        padding: 75px 0;
        div.top-section-01 {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .5s;
            width: 100%;
            img {
                @media screen and (max-width: $size-max-2) {
                    height: 9vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        height: 10vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            height: 80px;
                            transition: .5s;
                        }
                    }
                }
            }
        }
        div.body-section-01 {
            widows: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin-top: 65px;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                padding: 10px 25px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    margin-top: 15px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        margin-top: 5px;
                        transition: .5s;
                    }
                }
            }
            h3, p {
                width: 100%;
                text-align: center;
                text-transform: none;
                color: $color-white;
                transition: .5s;
            }
            h3 {
                margin-bottom: 25px;
                font-size: 30px;
                @media screen and (max-width: $size-max-2) {
                    font-size: 25px;
                    transition: .5s;
                }
            }
            p {
                font-weight: 300;
                line-height: 25px;
                font-size: 22px;
                @media screen and (max-width: $size-max-2) {
                    font-size: 20px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 18px;
                        transition: .5s;
                    }
                }
            }
        }
    }
}

section.section-03-index {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(180deg, $color-white, rgb(168, 168, 168)); /* Standard syntax (must be last) */
        transition: .5s;
        padding: 0;
        @media screen and (max-width: $size-max-m-2) {
            flex-direction: column;
            transition: .5s;
        }
        div.left-side-sect-03,
        div.right-side-burst {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                width: 100%;
                transition: .5s;
            }
        }
        div.left-side-sect-03 {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .5s;
            ul.list-services {
                width: 70%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                list-style-type: none;
                list-style: none;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 82%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 100%;
                        padding: 65px 0;
                        padding-bottom: 0;
                        align-items: center;
                        transition: .5s;
                    }
                }
                h3 {
                    width: 100%;
                    font-size: 38px;
                    line-height: 38px;
                    text-align: right;
                    text-transform: none;
                    color: $color-black;
                    padding-right: 1vw;
                    margin-bottom: 2.8vw;
                    font-weight: 600;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 32px;
                        line-height: 32px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 28px;
                            line-height: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                text-align: center;
                                transition: .5s;
                            }
                        }
                    }
                }
                li.item {
                    width: 100%;
                    text-align: right;
                    text-transform: none;
                    font-size: 28px;
                    line-height: 38px;
                    transition: .5s;                    
                    align-items: center;
                    justify-content: flex-end; 
                    color: $color-black;
                    margin-bottom: 1.2vw;
                    font-weight: 500;         
                    transition: .5s;
                    display: flex;
                    img {
                        margin: 0 20px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            margin: 0 10px;
                            transition: .5s;
                        }
                    }
                    @media screen and (max-width: $size-max-2) {
                        font-size: 25px;
                        line-height: 32px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 22px;
                            line-height: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 18px;
                                line-height: 20px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 15px;
                                    line-height: 18px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        justify-content: center;
                                        flex-direction: row-reverse;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div.right-side-burst {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            //height: 45vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                align-items: center;
                padding: 25px;
                transition: .5s;
            }
            div.box-1,
            div.box-2,
            div.box-3 {
                width: 97%;
                height: 30%;
                min-width: 300px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $color-3;
                margin-bottom: 25px;
                position: relative;
                overflow: hidden;
                transition: .5s;
                padding: 0;
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    transition: .5s;
                }
                &::after {
                    content: "";
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: $color-grey-transparent;                    
                    transition: .5s;
                    z-index: 55;
                    opacity: 0;
                }
                &:hover {
                    transition: .5s;
                    &::after {
                        opacity: 1;
                        transition: .5s;
                    }
                    div.border {
                        z-index: 99;
                        transition: .5s;
                        h3 {
                            color: $color-3;
                            //transition: .5s;
                            text-shadow: 0 -1px 4px $color-3,
                                0 -2px 10px $color-3, 
                                0 -10px 10px $color-3, 
                                0 -10px 10px $color-3;
                        }
                        .right-side {
                            h3 {
                                color: $color-3;
                                //transition: .5s;
                                text-shadow: 0 -1px 4px $color-3,
                                    0 -2px 10px $color-3, 
                                    0 -10px 10px $color-3, 
                                    0 -10px 10px $color-3;
                            }
                        }
                    }
                }
                a.content-border {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;                    
                    transition: .5s;
                }
                div.content-img {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                    padding: 0;
                    img {
                        width: 100%;
                        transition: .5s;
                    }
                }
                div.border {
                    width: 80%;
                    height: 60%;
                    display: flex;
                    align-items: center;
                    justify-content: center;                    
                    border: 1px solid $color-border;
                    position: absolute;
                    transition: .5s;
                }
            }            
            div.box-1 {
                div.border {
                    //position: relative;
                    flex-direction: column;
                    background: $color-4;
                    h3 {
                        font-size: 2.8vw;
                        font-weight: 400;
                        text-transform: none;
                        transition: .5s;
                        text-shadow: 2px 2px 5px $color-shadow,
                             -2px -2px 5px $color-shadow,
                             2px -2px 5px $color-shadow,
                             -2px 2px 5px $color-shadow;
                        span {
                            font-weight: 600;
                            text-transform: uppercase;
                            //transition: .5s;
                        }
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 4.8vw;
                            font-weight: 400;
                            transition: .5s;
                        }
                    }
                    label {
                        color: $color-white;
                        transition: .5s;
                        position: absolute;
                        bottom: 20px;
                        @media screen and (max-width: $size-max-2) {
                            bottom: 1vw;
                            font-size: 17px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 15px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    //bottom: -1vw;
                                    font-size: 12px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
            div.box-2 {
                height: 25%;
                div.border {
                    flex-direction: column;
                    background: $color-grey;
                    padding-left: 8vw;
                    @media screen and (max-width: $size-max-m-2) {
                        padding: 0;
                        transition: .5s;
                    }
                    h3 {
                        width: 100%;
                        transition: .5s;
                        text-align: left;
                        text-shadow: 2px 2px 5px $color-shadow,
                             -2px -2px 5px $color-shadow,
                             2px -2px 5px $color-shadow,
                             -2px 2px 5px $color-shadow;
                        &:nth-child(1) {
                            font-size: 2.4vw;
                            font-weight: 600;
                            @media screen and (max-width: $size-max-m-2) {
                                text-align: center;
                                font-size: 4.8vw;
                                transition: .5s;
                            }
                        }
                        &:nth-child(2) {
                            font-size: 2.0vw;
                            font-weight: 300;
                            text-transform: none;
                            @media screen and (max-width: $size-max-m-2) {
                                text-align: center;
                                font-size: 4.0vw;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
            div.box-3 {
                margin-bottom: 0;
                margin: 0;
                div.border {
                    background: $color-blue-clean;
                    padding: 15px;
                    div.left-side,
                    div.right-side {
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        transition: .5s;
                    }
                    div.left-side {
                        justify-content: center;
                    }
                    div.right-side {
                        width: 50%;
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        justify-content: center;
                        padding-left: 5vw;
                        transition: .5s;                        
                        h3 {
                            width: 100%;
                            transition: .5s;
                            text-align: left;
                            text-shadow: 2px 2px 5px $color-shadow,
                                -2px -2px 5px $color-shadow,
                                2px -2px 5px $color-shadow,
                                -2px 2px 5px $color-shadow;
                            &:nth-child(1),
                            &:nth-child(3) {
                                font-size: 2.4vw;
                                font-weight: 600;
                                @media screen and (max-width: $size-max-m-2) {
                                    //text-align: center;
                                    font-size: 4.8vw;
                                    transition: .5s;
                                }
                            }
                            &:nth-child(2) {
                                font-size: 2.0vw;
                                font-weight: 600;
                                text-transform: none;
                                @media screen and (max-width: $size-max-m-2) {
                                    //text-align: center;
                                    font-size: 4.0vw;
                                    transition: .5s;
                                }
                            }
                        }                        
                    }
                    label {                        
                        right: 1vw;
                        font-size: 19px;
                        font-weight: 500;
                        color: $color-white;                        
                        position: absolute;
                        transition: .5s;
                        bottom: -2vw;
                        @media screen and (max-width: $size-max-2) {
                            bottom: -3vw;
                            font-size: 17px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 15px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    bottom: -5vw;
                                    font-size: 12px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section-05-index {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    div.container-fluid.row {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 75px 0;
        transition: .5s;
        div.header-section-05 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .5s;
            h3 {
                width: 100%;
                padding-top: 35px;
                text-align: center;
                color: $color-black;
                text-transform: none;
                font-weight: 500;
                font-size: 35px;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    font-size: 30px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 25px;
                        padding-top: 25px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 22px;
                            padding-top: 15px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 20px;
                                padding-top: 5px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 18px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
        div.body-section-05 {
            width: 65%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 35px;
            flex-wrap: wrap;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                width: 75%;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        width: 85%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            width: 90%;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                width: 100%;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
            div.content-logo {
                margin: 20px 35px;                
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) { 
                    width: 28%;                                  
                    transition: .5s;
                }
                img {
                    @media screen and (max-width: $size-max-2) {
                        height: auto;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                //height: 9vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    //min-height: 65px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {                                       
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
            }                          
        }
    }
}

body.commercial-page {
    section.section-01-commercial {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        div.container-fluid.row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .5s;
            padding: 0;
            @media screen and (max-width: $size-max-m-2) {
                flex-direction: column;
                transition: .5s;
            }
            div.left-side-sect-01-commercial,
            div.rigth-side-sect-01-commercial {
                width: 50%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    transition: .5s;
                }
            }
            div.left-side-sect-01-commercial {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background: url(../images/bg_commercial_orange.jpg) repeat-x center center;
                background-size: 100% 100%;
                padding: 15px 4vw;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    padding: 2vw 2vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        padding-bottom: 0;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            min-height: 250px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                padding: 65px 15px;
                                transition: .5s;
                            }
                        }
                    }
                }
                div.content-paragraph {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        padding-bottom: 0;
                        transition: .5s;
                    }
                    p {
                        font-size: 1.2vw;
                        line-height: 1.5vw;
                        color: $color-white;
                        font-weight: 300;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 16px;
                            line-height: 22px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 14px;
                                line-height: 18px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 15px;
                                    text-align: center;
                                    line-height: 22px;
                                    transition: .5s;
                                }
                            }
                        }
                        span.big-letters {
                            font-size: 1.8vw;
                            font-weight: 400;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 20px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        font-size: 22px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            div.rigth-side-sect-01-commercial {
                div.content-img-sect-01-commerce {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        transition: .5s;
                    }
                }
            }
        }
    }

    section.section-02-commercial {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: url(../images/bg_commercial_gris.jpg) no-repeat center center;
        background-size: 100% 100%;
        transition: .5s;
        div.container-fluid.row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 75px 10px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                flex-direction: column;
                transition: .5s;
            }
            div.left-side-sect-02-commercial,
            div.right-side-sect-02-commercial {
                width: 50%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 100%;
                    transition: .5s;
                }
            }
            div.left-side-sect-02-commercial {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .5s;
                div.content-img {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                }
            }
            div.right-side-sect-02-commercial {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    margin-top: 65px;
                    transition: .5s;
                }
                div.header-sect-02-commercial {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 25px;
                    transition: .5s;
                    h3.title-list-services {
                        width: 47%;
                        color: $color-black;
                        text-transform: none;
                        text-align: right;
                        font-size: 2.2vw;
                        font-weight: 500;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                width: 90%;
                                text-align: center;
                                transition: .5s;
                            }
                        }
                    }
                }
                
                div.content-lists-commercial {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        flex-direction: column;
                        transition: .5s;
                    }
                    ul.list-services {
                        width: 50%;
                        transition: .5s;
                        padding: 0;
                        @media screen and (max-width: $size-max-m-3) {
                            width: 100%;
                            transition: .5s;
                        }
                        li.item {
                            display: flex;
                            align-items: center;
                            text-transform: none;
                            margin-bottom: 1.2vw;
                            line-height: 1.4vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                margin-bottom: 15px;
                                line-height: 20px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-4) {
                                    margin-bottom: 10px;
                                    line-height: 15px;
                                }
                            }
                            span {
                                color: $color-black;
                                font-weight: 500;
                                font-size: 1.2vw;
                                @media screen and (max-width: $size-max-3) {
                                    font-size: 16px;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 14px;
                                    }
                                }
                            }
                            img {
                                margin: 0 15px;
                            }
                        }
                        &:nth-child(1) {
                            @media screen and (max-width: $size-max-m-1) {
                                padding-right: 8vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-3) {
                                    justify-content: center;
                                }
                            }
                            li.item {
                                text-align: right;
                                justify-content: flex-end;  
                                @media screen and (max-width: $size-max-m-3) {
                                    justify-content: center;
                                    flex-direction: row-reverse;
                                }
                            }
                        }
                        &:nth-child(2) {  
                            justify-content: flex-start; 
                            @media screen and (max-width: $size-max-m-3) {
                                justify-content: center;
                                flex-direction: column;
                            }                         
                            li.item {
                                text-align: left;
                                justify-content: flex-end;   
                                flex-direction: row-reverse;
                                @media screen and (max-width: $size-max-m-3) {
                                    justify-content: center;
                                    flex-direction: row-reverse;
                                }
                            }
                        }
                    }
                }
            }
        }

        div.container-fluid.second {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 45px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                flex-direction: column;
                transition: .5s;
            }
        }
    }

    section.section-03-commercial {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(../images/bg_commercial_orange.jpg) repeat-x center center;
        background-size: 100% 100%;
        transition: .5s;
        div.container-fluid.row {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding: 65px 15px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                flex-direction: column-reverse;
                transition: .5s;
            }
            div.left-side-sect-03-commrcial {
                width: 60%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 100%;
                    transition: .5s;
                }
                div.content-lists-commercial {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        margin-top: 35px;
                        @media screen and (max-width: $size-max-m-3) {
                            flex-direction: column;
                            transition: .5s;                        
                        }
                    }                    
                    ul.list-systemes {
                        width: 50%;
                        transition: .5s;
                        padding: 0;
                        @media screen and (max-width: $size-max-m-3) {
                            width: 100%;
                            transition: .5s;
                        }
                        li.item {
                            display: flex;
                            align-items: center;
                            text-transform: none;
                            margin-bottom: 1.2vw;
                            line-height: 1.4vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                margin-bottom: 15px;
                                line-height: 20px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-4) {
                                    margin-bottom: 10px;
                                    line-height: 15px;
                                }
                            }
                            span {
                                color: $color-white;
                                font-weight: 500;
                                font-size: 1.2vw;
                                @media screen and (max-width: $size-max-3) {
                                    font-size: 16px;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 14px;
                                    }
                                }
                            }
                            img {
                                margin: 0 15px;
                            }
                        }
                        &:nth-child(1) {
                            @media screen and (max-width: $size-max-m-1) {
                                padding-right: 8vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-3) {
                                    justify-content: center;
                                }
                            }
                            li.item {
                                text-align: right;
                                justify-content: flex-end;  
                                @media screen and (max-width: $size-max-m-3) {
                                    justify-content: center;
                                    flex-direction: row-reverse;
                                }
                            }
                        }
                        &:nth-child(2) {  
                            justify-content: flex-start; 
                            @media screen and (max-width: $size-max-m-3) {
                                justify-content: center;
                                flex-direction: column;
                            }                         
                            li.item {
                                text-align: left;
                                justify-content: flex-end;   
                                flex-direction: row-reverse;
                                @media screen and (max-width: $size-max-m-3) {
                                    justify-content: center;
                                    flex-direction: row-reverse;
                                }
                            }
                        }
                    }
                }
            }
            div.right-side-sect-03-commercial {
                width: 40%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 100%;
                    transition: .5s;
                }
                div.content-paragraph {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        padding-bottom: 0;
                        transition: .5s;
                    }
                    p {
                        font-size: 1.2vw;
                        line-height: 1.5vw;
                        color: $color-white;
                        font-weight: 300;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 16px;
                            line-height: 22px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 14px;
                                line-height: 22px;
                                text-align: center;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 15px;
                                    line-height: 22px;
                                    transition: .5s;
                                }
                            }
                        }
                        span.big-letters {
                            font-size: 1.8vw;
                            font-weight: 400;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 20px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        font-size: 22px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
                div.content-logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 15px;
                    transition: .5s;
                }
            }
        }
    }    
}

////////////////// PAGE EMPLOIS - LIST EMPLOIS
section.section-01-emplois-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $color-grey;
    align-items: center;
    transition: .5s;
    padding: 75px 0;
    width: 100%;

    div.header-sect-02-emplois {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 30px 0;
        padding-top: 25px;
        margin-bottom: 85px;

        h3 {
            color: $color-white;
            font-size: 35px;
            font-weight: 600;
            padding-bottom: 85px;
            padding-top: 25px;
            padding: 0 1.2vw;
        }

        div.content-paragraph {
            width: 70%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                padding-bottom: 0;
                transition: .5s;
                width: 80%;
                @media screen and (max-width: $size-max-m-3) {
                    transition: .5s;
                    width: 95%;
                }
            }
            p {
                font-size: 1.2vw;
                line-height: 1.5vw;
                text-align: center;
                color: $color-white;
                font-weight: 300;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    font-size: 16px;
                    line-height: 22px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 14px;
                        line-height: 18px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 15px;
                            text-align: center;
                            line-height: 22px;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }

    div.container-fluid.row {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        transition: .5s;
        width: 85%;
        @media screen and (max-width: $size-max-2) {
            width: 100%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-3) {
                justify-content: center;
                transition: .5s;
                padding: 5px;
            }
        }

        div.col-md-6.offre-emplois {
            margin-bottom: 85px;
            div.body-offert {
                h3, p {
                    color: $color-white;
                    text-transform: none;
                    @media screen and (max-width: $size-max-m-3) {
                        text-align: center;
                        transition: .5s;
                    }
                }
                h3.title-offert {
                    font-size: 25px;
                }

                h3.date-affiche {
                    font-size: 15px;
                    font-weight: 300;
                }
                h3.succursal-name {
                    font-size: 20px;
                    font-weight: 400;
                    margin-top: 15px;
                    margin-bottom: 5px;
                }
                div.content-description {
                    width: 100%;
                }
                .date-btm {
                    margin-top: 32px;
                }
            }

            div.footer-offert {
                border-top: 2px solid $color-white;
                margin-top: 10px;
                padding-top: 25px;
                @media screen and (max-width: $size-max-m-3) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                }

                a.btn-simple-postuler {
                    width: 230px;
                    height: 65px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $color-3;
                    color: $color-black;
                    font-size: 20px;
                    font-weight: 500;
                    text-transform: uppercase;
                    transition: .5s;
                    border: 2px solid transparent;
                    &:hover {
                        background: $color-2;
                        border: 2px solid $color-black;
                        transition: .5s;
                    }
                }
            }
        }
    }
}

section.section-01-realistions {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: $color-grey;
    transition: .5s;
    div.content-header-section-01 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 45px 15px;
        padding-bottom: 0;
        margin-top: 45px;
        transition: .5s;
        a {
            color: $color-3;
            font-weight: 400;
            font-size: 18px;
            transition: .5s;
            margin-bottom: 15px;
            &:hover {
                color: $color-white;
                transition: .5s;
            }
        }
        p {
            width: 65%;
            font-size: 1.2vw;
            line-height: 1.5vw;
            text-align: center;
            color: $color-white;
            font-weight: 300;
            transition: .5s;
            @media screen and (max-width: $size-max-4) {
                width: 75%;
                font-size: 16px;
                line-height: 22px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    font-size: 14px;
                    line-height: 18px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 95%;
                        font-size: 15px;
                        text-align: center;
                        line-height: 22px;
                        transition: .5s;
                    }
                }
            }
        }
    }
    div.container-fluid.row {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 65px 15px;
        min-height: 350px;
        transition: .5s;
        @media screen and (max-width: $size-max-1) {
            width: 94%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-3) {
                width: 100%;
                transition: .5s;
            }
        }
        div.content-groups-photos {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            transition: .5s;
            div.unique-photo {
                width: 24%;
                margin: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background: $color-black;
                margin-bottom: 45px;
                transition: .5s;
                min-width: 300px;

                -webkit-box-shadow: 4px 4px 21px 0px rgba(0, 0, 0, 1);
                -moz-box-shadow:    4px 4px 21px 0px rgba(0, 0, 0, 1);
                box-shadow:         4px 4px 21px 0px rgba(0, 0, 0, 1);
                h3 {
                    min-height: 45px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    text-transform: none;
                    color: $color-white;
                    font-size: 1.2vw;
                    font-weight: 400;
                    transition: 0s;
                    padding: 15px;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 16px;
                        transition: .5s;
                    }
                }
            }
        }
        div.content-groups-projects {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                flex-direction: column;
                transition: .5s;
            }
            div.project-group {
                width: 48%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $color-black;
                flex-direction: column;
                transition: .5s;
                margin: 15px;
                @media screen and (max-width: $size-max-1) {
                    width: 45%;
                    margin-bottom: 4vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        width: 95%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-3) {
                            margin: 0;
                            width: 100%;
                            transition: .5s;
                            margin-bottom: 65px;
                        }
                    }
                }
                div.header-project {
                    width: 100%;
                    min-height: 125px;
                    display: flex;
                    position: relative;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: column;
                    margin-bottom: 0;
                    overflow: hidden;
                    padding: 15px; 
                    &::after {
                        content: "";
                        width: 100%;
                        height: 25px;
                        background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.959));
                        position: absolute;
                        bottom: -2px;
                        right: 0;
                        left: 0;
                        display: none;
                    }                   
                    h3 {
                        color: $color-3;
                        transition: .5s;
                        text-transform: uppercase;
                        font-weight: 400;
                        font-size: 1.0vw;
                        margin-bottom: 15px;
                        width: 100%;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 1.5vw;
                            margin-bottom: 10px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 1.8vw;
                                margin-bottom: 0px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 20px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        font-size: 18px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-4) {
                                            font-size: 16px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    p {                        
                        transition: .5s;
                        font-size: 16px;
                        line-height: 20px;
                        text-transform: none;
                        color: $color-white;
                        font-weight: 300;
                        width: 100%;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 15px;
                            line-height: 19px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                font-size: 14px;
                                line-height: 18px;
                                transition: .5s;
                            }
                        }
                    }
                }
                div.body-project {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        flex-direction: column;
                        transition: .5s;
                    }
                    div.content-principal-cover {
                        width: 75%;
                        height: 16.0vw;
                        display: flex;
                        overflow: hidden;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 15px;
                        margin-left: 15px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-1) {
                            height: 16.5vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                height: 41vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-3) {
                                    margin: 0;
                                    width: 100%;
                                    height: 48vw;
                                    transition: .5s;
                                }
                            }
                        }                                              
                        a.content-link {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            transition: .5s;
                            &::before {
                                content: "";
                                transition: .5s;
                                background: $color-3;
                                position: absolute;
                                opacity: 0;
                                bottom: 0;
                                right: 0;
                                left: 0;
                                top: 0;
                            }
                            &:hover {
                                &::before {
                                    transition: .5s;
                                    opacity: .7;
                                }
                            }
                            img {
                                width: 100%;
                                transition: .5s;
                            }
                        }                        
                    }
                    div.content-lateral-covers {
                        width: 25%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        position: relative;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            height: 42vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-3) {
                                width: 100%;
                                height: 22vw;
                                flex-direction: row;
                                transition: .5s;
                            }
                        } 
                        div.content-thumb {
                            width: 88%;
                            display: flex;
                            overflow: hidden;
                            align-items: center;
                            justify-content: center;
                            max-height: 5vw;
                            transition: .5s;
                            margin: 10px;
                            @media screen and (max-width: $size-max-m-1) {
                                height: 42vw;
                                max-height: 100%;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-3) {
                                    height: 80%;
                                    transition: .5s;
                                }
                            }
                            &:nth-child(1) {
                                margin-top: 0;
                                @media screen and (max-width: $size-max-m-3) {
                                    margin: 10px;
                                 }
                            }
                            &:nth-child(2) {
                                margin-top: 0;
                                margin-bottom: 0;
                                @media screen and (max-width: $size-max-m-3) {
                                    margin: 10px;
                                 }
                            }
                            &.last-extrat {
                                height: 5vw;
                                position: relative;
                                align-items: center;
                                justify-content: center;
                                background: rgba(0, 0, 0, 0.726);
                                position: absolute;
                                display: flex;
                                bottom: 10px;
                                margin: 0;
                                @media screen and (max-width: $size-max-m-1) {
                                    height: 14vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-3) {
                                        transition: .5s;
                                        height: 20vw;
                                        width: 30vw;
                                        right: 0;
                                        top: 0;
                                    }
                                }
                                a.val-extrat {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    padding: 18% 0;
                                    align-items: center;
                                    justify-content: center;
                                    color: $color-white;
                                    font-weight: 500;
                                    font-size: 22px;
                                    transition: .5s;
                                    span {
                                        margin-left: 5px;
                                        padding-top: 5px;
                                        font-weight: 300;
                                        font-size: 14px;
                                    }
                                    &:hover {
                                        color: $color-3;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
                div.footer-project {
                    width: 100%;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                    hr {
                        width: 75%;
                        border-top: 1px solid $color-white;
                        transition: .5s;
                    }
                }
            }            
        }
    }
}

section.section-01-contact {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../images/bg_formulaire_orange.jpg) no-repeat center center;
    background-size: 100% 100%;
    transition: .5s;
    div.container-fluid.row {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 85px 15px;
        flex-wrap: wrap;
        transition: .5s;
        @media screen and (max-width: $size-max-2) {
            width: 92%;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                width: 100%;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    padding-left: 10vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        padding-left: 5vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            padding-left: 7vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                flex-direction: column;
                                justify-content: center;
                                padding: 45px 15px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
        div.box-infos {
            width: 25%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 45px;
            transition: .5s;
            @media screen and (max-width: $size-max-4) {
                width: 30%;
                margin: 25px 5px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 45%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-4) {
                        width: 95%;
                        transition: .5s;
                    }
                }
            }
            h3.nom-emplye {
                font-size: 25px;
                color: $color-white;
                font-weight: 400;
                width: 100%;
                @media screen and (max-width: $size-max-2) {
                    font-size: 22px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 18px;
                        transition: .5s;
                    }
                }
            }
            h4.function-employer {
                font-size: 22px;
                padding: 5px 0;
                color: $color-white;
                font-weight: 300;
                width: 100%;
                @media screen and (max-width: $size-max-2) {
                    font-size: 20px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 16px;
                        transition: .5s;
                    }
                }
            }
            div.phone-contacts {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .5s;
                a {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 15px;
                    transition: .5s;
                    &:hover {
                        .phone-number {
                            color: $color-grey-dark;
                            transition: .5s;
                        }
                        div.icons {
                            transition: .5s;
                            img {
                                transition: .5s;
                                &:nth-child(1) {
                                    opacity: 0;
                                }
                                &:nth-child(2) {
                                    opacity: 1;
                                }
                            }
                        }
                        transition: .5s;
                    }
                    div.icons {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .5s;
                        img {
                            //margin-right: 5px;
                            transition: .5s;
                            &:nth-child(1) {
                                opacity: 1;
                            }
                            &:nth-child(2) {
                                position: absolute;
                                opacity: 0;
                            }
                        }
                    }
                    span {
                        color: $color-white;
                        transition: .5s;
                    }
                    .phone-number {
                        font-weight: 500;
                        font-size: 1.8vw;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 25px;
                            transition: .5s;
                        }
                    }
                    .separator-line {
                        font-weight: 300;
                        font-size: 1.2vw;
                        margin: 0 5px;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 15px;
                            transition: .5s;
                        }
                    }
                    .extension {
                        font-weight: 300;
                        font-size: 1.0vw;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 15px;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
}

section.setion-02-contact {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        padding: 0;
        @media screen and (max-width: $size-max-m-1) {
            flex-direction: column;
            transition: .5s;
        }
        div.left-side-contact-infos,
        div.map {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                transition: .5s;
            }
        }
        div.left-side-contact-infos {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: url(../images/bg_coordonnees_gris.jpg) no-repeat center center;
            background-size: 100% 100%;
            transition: .5s;
            padding: 0 12vw;
            @media screen and (max-width: $size-max-4) {
                padding: 0 8vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    padding: 0 2vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        padding: 65px 2vw;
                        transition: .5s;
                    }
                }
            }
            div.header-infos {
                width: 100%;
                transition: .5s;
                margin-bottom: 25px;
            }
            div.line-infos {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 2vw;
                transition: .5s;
                margin: 25px 0;
                @media screen and (max-width: $size-max-4) {
                    margin: 20px 0;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        margin: 15px 0;
                        padding-left: 6vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            margin: 15px 0;
                            transition: .5s;
                        }
                    }
                }
                div.icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 12px;
                    transition: .5s;
                    img {
                        transition: .5s;
                        &:nth-child(1) {
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            position: absolute;
                            opacity: 0;
                        }
                    }
                }
                h3 {
                    font-weight: 300;
                    font-size: 1.5vw;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 25px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-3) {
                            font-size: 20px;
                            transition: .5s;
                        }
                    }
                }
                a.phone {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    transition: .5s;
                    &:hover {
                        transition: .5s;
                        span {
                            color: $color-3;
                            transition: .5s;
                        }
                        div.icon {
                            transition: .5s;
                            img {
                                transition: .5s;
                                &:nth-child(1) {
                                    opacity: 0;
                                }
                                &:nth-child(2) {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    span {
                        color: $color-white;
                        font-weight: 300;
                        font-size: 1.8vw;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 30px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-3) {
                                font-size: 25px;
                                transition: .5s;
                            }
                        }
                    }
                }
                a.email {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    transition: .5s;
                    line-height: 35px;
                    span {
                        color: $color-white;
                        font-weight: 600;
                        font-size: 1.5vw;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 25px;
                            line-height: 30px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-3) {
                                font-size: 22px;
                                line-height: 25px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section-logos {
    display: flex;
    justify-content: center;
    background: $color-5;
    align-items: center;
    padding: 25px 0;

    div.container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;        
        transition: .5s;        
        width: 100%;

        div.header-section {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            h3 {
                color: $color-white;
                text-transform: none;
                padding-left: 5vw;
                font-weight: 400;
                font-size: 26px;
                width: 100%;
                @media screen and (max-width: $size-max-2) {
                    font-size: 24px;
                    padding-left: 4vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 20px;
                        padding-left: 3vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 18px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 16px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    text-align: center;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }

        div.body-section-logos {
            margin-top: 45px;
            max-height: 300px;
            overflow: hidden;
            width: 100%;
            @media screen and (max-width: $size-max-2) {
                margin-top: 35px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    margin-top: 15px;
                    transition: .5s;
                }
            }
            div.container {
                width: 100%;
                transition: .5s;
            }

            .main-content {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .owl-carousel {
                    width: 95%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-4) {
                        width: 100%;
                        transition: .5s;
                    }
                    .owl-stage-outer {
                        padding-left: 15vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            padding: 15px;
                            transition: .5s;
                        }
                    }
                }
                .owl-theme {
                    .custom-nav {
                        position: absolute;                        
                        left: 0;
                        top: 0vw;
                        right: 0;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        @media screen and (max-width: $size-max-1) {
                            transition: .5s;
                        }          
                        .owl-prev, .owl-next {
                            position: absolute;
                            height: 100px;
                            color: inherit;
                            background: none;
                            border: none;
                            z-index: 100;
                            i, span {
                                font-size: 2.5rem;
                                color: $color-white;
                                @media screen and (max-width: $size-max-2) {
                                    font-size: 2.0rem;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-3) {
                                        font-size: 1.5rem;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
            
                        .owl-prev {
                            left: 0;
                        }
            
                        .owl-next {
                            right: 0;
                        }
                    }
                }

                .owl-dots {
                    display: none;
                }
            }
        }
    }
}

div.empty-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    h3 {
        width: 100%;
        font-size: 15px;
        font-weight: 400;
        text-transform: none;
        text-align: center;
        transition: .5s;
    }
}

.image-link {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
  }
  
  /* aligns caption to center */
  .mfp-title {
    text-align: center;
    padding: 6px 0;
  }
  

.full-text {
    display: none;
}

.voir-moins,
.voir-plus {
    font-size: 18px;
    color: $color-3;
    cursor: pointer;
    transition: .5s;
    &:hover {
        color: $color-black;
        transition: .5s;
    }
}

div.custom-separator {
    position: relative;
    &::after {
        content: '';        
        top: 100%;  
        z-index: 1;
        height: 10px;
        width: 100%;
        transition: .5s;
        position: absolute;
        background: $color-6;
        border: 1px solid $color-3;
        border-right: 0;
        border-left: 0;      
    }
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 1;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.596), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';
@import 'sections/section-bursts-icons';
@import 'sections/section-bursts-2b';

@import 'sections/section-wyswyg2';


/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* X PAGE
*/

.pageX {
    section.header {

    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}


@media screen and (max-width: $size-xs-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
