footer {
    height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: $size-footer-width-desktop;
        margin: 0 auto;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-around;
        @extend .text-color-grey;
        @extend .font-footer;
        flex-direction: column;
        margin-top: 1vw;
        .copyright {
            //font-size: 15px;
            margin-bottom: 10px;
        }
        .mediaweb {
            //font-size: 15px;
            margin-bottom: 10px;
            a {
                @extend .text-color-grey;
                @extend .flex;
                @extend .items-center;
                img {
                    margin: -7px 0 0 0;
                }
                span { padding-left: 5px;}
                &:hover {
                    @extend .text-color-contrast;
                }
            }
        }
        @media screen and (max-width: $size-xs-max) {
            width: $size-footer-width-mobile;
        }
    }
}
