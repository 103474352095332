div.box-1,
div.box-3 {
    width: 50;
    height: 30%;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-3;
    margin-bottom: 25px;
    position: relative;
    overflow: hidden;
    transition: .5s;
    padding: 0;

    -webkit-box-shadow: 4px 4px 21px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow:    4px 4px 21px 0px rgba(0, 0, 0, 1);
    box-shadow:         4px 4px 21px 0px rgba(0, 0, 0, 1);

    @media screen and (max-width: $size-max-m-2) {
        width: 100%;
        transition: .5s;
    }
    &::after {
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: $color-grey-transparent;                    
        transition: .5s;
        z-index: 55;
        opacity: 0;
    }
    &:hover {
        transition: .5s;
        &::after {
            opacity: 1;
            transition: .5s;
        }
        div.border {
            z-index: 99;
            transition: .5s;
            h3 {
                color: $color-3;
                //transition: .5s;
                text-shadow: 0 -1px 4px $color-3,
                    0 -2px 10px $color-3, 
                    0 -10px 10px $color-3, 
                    0 -10px 10px $color-3;
            }
            .right-side {
                h3 {
                    color: $color-3;
                    //transition: .5s;
                    text-shadow: 0 -1px 4px $color-3,
                        0 -2px 10px $color-3, 
                        0 -10px 10px $color-3, 
                        0 -10px 10px $color-3;
                }
            }
        }
    }
    a.content-border {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;                    
        transition: .5s;
    }
    div.content-img {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        padding: 0;
        img {
            width: 100%;
            transition: .5s;
        }
    }
    div.border {
        width: 80%;
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: center;                    
        border: 1px solid $color-border;
        position: absolute;
        transition: .5s;
    }
}            
div.box-1 {
    margin-right: 10px;
    div.border {
        //position: relative;
        flex-direction: column;
        background: $color-4;
        h3 {
            font-size: 2.8vw;
            font-weight: 400;
            text-transform: none;
            transition: .5s;
            text-shadow: 2px 2px 5px $color-shadow,
                    -2px -2px 5px $color-shadow,
                    2px -2px 5px $color-shadow,
                    -2px 2px 5px $color-shadow;
            span {
                font-weight: 600;
                text-transform: uppercase;
                //transition: .5s;
            }
            @media screen and (max-width: $size-max-m-2) {
                font-size: 4.8vw;
                font-weight: 400;
                transition: .5s;
            }
        }
        label {
            color: $color-white;
            transition: .5s;
            position: absolute;
            bottom: 20px;
            @media screen and (max-width: $size-max-2) {
                bottom: 1vw;
                font-size: 17px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 15px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        //bottom: -1vw;
                        font-size: 12px;
                        transition: .5s;
                    }
                }
            }
        }
    }
}

div.box-3 {
    margin-left: 10px;
    div.border {
        background: $color-blue-clean;
        padding: 15px;
        div.left-side,
        div.right-side {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: .5s;
        }
        div.left-side {
            justify-content: center;
        }
        div.right-side {
            width: 50%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            padding-left: 5vw;
            transition: .5s;                        
            h3 {
                width: 100%;
                transition: .5s;
                text-align: left;
                text-shadow: 2px 2px 5px $color-shadow,
                    -2px -2px 5px $color-shadow,
                    2px -2px 5px $color-shadow,
                    -2px 2px 5px $color-shadow;
                &:nth-child(1),
                &:nth-child(3) {
                    font-size: 2.4vw;
                    font-weight: 600;
                    @media screen and (max-width: $size-max-m-2) {
                        //text-align: center;
                        font-size: 4.8vw;
                        transition: .5s;
                    }
                }
                &:nth-child(2) {
                    font-size: 2.0vw;
                    font-weight: 600;
                    text-transform: none;
                    @media screen and (max-width: $size-max-m-2) {
                        //text-align: center;
                        font-size: 4.0vw;
                        transition: .5s;
                    }
                }
            }                        
        }
        label {                        
            right: 1vw;
            font-size: 19px;
            font-weight: 500;
            color: $color-white;                        
            position: absolute;
            transition: .5s;
            bottom: -2vw;
            @media screen and (max-width: $size-max-2) {
                bottom: -3vw;
                font-size: 17px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 15px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        bottom: -5vw;
                        font-size: 12px;
                        transition: .5s;
                    }
                }
            }
        }
    }
}