.slideshow-wrap {
    position: relative;
    background-color: $bg-color-body;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }
    .title {
        @extend .font-2-normal;
        text-transform: uppercase;
        color: $color-primary;
        font-size: $font-size-50;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        z-index: $z-index-slide-caption;
        padding-left: 10vw;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            padding-left: 15vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-3) {
                margin-top: 8vw;
                padding-left: 17vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                    margin-top: -2vw;
                    transition: .5s;
                }
            }
        }
        div.row.text {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: .5s;
            ul.list-caption {
                width: 50%;
                height: 100%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                    width: 100%;
                    margin-top: -15vw;
                    transition: .5s;
                }
                li.item {
                    text-transform: none;
                    color: $color-white;                    
                    line-height: 3.5vw;
                    font-size: 2.3vw;
                    font-weight: 600;
                    transition: .5s;
                    text-shadow: 2px 2px 5px $color-shadow,
                             -2px -2px 5px $color-shadow,
                             2px -2px 5px $color-shadow,
                             -2px 2px 5px $color-shadow;
                    @media screen and (max-width: 1299px) {
                        line-height: 3.5vw;
                        font-size: 2.6vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            line-height: 4.2vw;
                            font-size: 3.0vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-3) {
                                line-height: 5.2vw;
                                font-size: 3.5vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-4) {
                                    width: 100%;
                                    line-height: 7.8vw;
                                    font-size: 5.5vw;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
        .caption {
            top: $size-nav-height-mobile + 20px;
        }

    }

    a.ls-gui-element.ls-nav-prev,
    a.ls-gui-element.ls-nav-next {
        border: 2px solid $color-3!important;
        transition: .5s;
        &::after, 
        &::before {
            background-color: $color-3;
            transition: .5s;
        }        
    }

    div.ls-gui-element.ls-bottom-nav-wrapper {
        a.ls-nav-start.ls-nav-start-active {
            &::before {
                //background-color: $color-3;
                transition: .5s;                
            }
        }
        span.ls-bottom-slidebuttons {
            transition: .5s;
            a {                
                border: 2px solid $color-3!important;
                transition: .5s;
                &::before {
                    background: $color-3;
                    transition: .5s;
                }
                &.ls-nav-active {
                    background: $color-3;
                    transition: .5s;
                }
            }
        }
    }
}

